<template>
  <v-container fluid class="px-2 py-2">
    <v-row>
      <v-col cols="12" class="pb-0">
        <h3 class="text-h3 text-grt font-weight-bold mt-2">環境設置</h3>
      </v-col>
      <v-stepper
        alt-labels
        elevation="0"
        v-model="e1"
        class="bg-transparent overflow-visible w-100 mx-auto"
      >
        <v-row>
          <v-col lg="8" cols="12" class="mx-auto mt-2">
            <v-stepper-header class="shadow-0">
              <v-stepper-step step="1" color="#344767">
                <span class="text-secondary font-weight-normal">修改密碼</span>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="2" color="#344767">
                <span class="text-secondary font-weight-normal">模式</span>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3" color="#344767">
                <span class="text-secondary font-weight-normal">說明</span>
              </v-stepper-step>
            </v-stepper-header>
          </v-col>
        </v-row>

        <v-row>
          <v-col lg="8" cols="12" class="mx-auto">
            <v-stepper-items
              class="border-radius-xl overflow-hidden shadow-lg mt-2 mb-2"
            >
              <v-stepper-content
                step="1"
                class="bg-white border-radius-xl px-4 pt-4"
              >
                <v-card>
                  <div>
                    <!-- <v-row class="text-center">
                        <v-col cols="10" class="mx-auto">
                          <h5 class="text-h5 text-typo font-weight-normal mb-2">
                            請變更密碼
                          </h5>
  
                        </v-col>
                      </v-row> -->
                    <v-col cols="12">
                      <user-change-password
                        v-on:password-changed="onPasswordChanged"
                      ></user-change-password>
                    </v-col>
                  </div>
                  <div class="text-end">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-default
                        py-5
                        px-6
                        mt-2
                        mb-2
                        me-2
                      "
                      color="primary"
                      @click="e1 = 2"
                    >
                      下一步
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content>

              <v-stepper-content
                step="2"
                class="bg-white border-radius-xl px-4 pt-4"
              >
                <v-card>
                  <div>
                    <!-- <v-row class="text-center">
                      <v-col cols="10" class="mx-auto">
                        <h5 class="text-h5 text-grt font-weight-normal mb-2">
                          請選擇模式
                        </h5>
                        <p class="text-body">
                          系統提供兩種模式 一個是一般模式 一個是夜間模式
                          可以自由切換
                        </p>
                      </v-col>
                    </v-row> -->
                    <theme-mode
                      @updateSidebarTheme="updateSidebarTheme"
                    ></theme-mode>
                  </div>
                  <div class="d-flex mt-10">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs text-dark
                        btn-light
                        bg-gradient-light
                        py-5
                        px-6
                        mt-6
                        mb-2
                        ms-2
                      "
                      @click="e1 = 1"
                    >
                      回上一步
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-default
                        py-5
                        px-6
                        mt-6
                        mb-2
                        me-2
                        ms-auto
                      "
                      color="primary"
                      @click="e1 = 3"
                    >
                      下一步
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content>

              <v-stepper-content
                step="3"
                class="bg-white border-radius-xl px-4 pt-4"
              >
                <v-card>
                  <div>
                    <v-row class="text-center">
                      <!-- <v-col>
                        <v-img
                          src="@/assets/img/GRT正.jpg"
                          class="navbar-brand-img ms-3"
                          width="100px"
                          height="100px"
                          contain
                        >
                        </v-img>
                      </v-col> -->
                      <v-col cols="10" class="mx-auto">
                        <h5 class="text-h5 font-weight-normal mb-2">
                          設定完成
                        </h5>
                        <div class="d-flex align-center">
                          <v-img
                            src="@/assets/img/GRT正.jpg"
                            class="navbar-brand-img ms-3 mx-auto"
                            width="100px"
                            height="100px"
                            contain
                          >
                          </v-img>
                        </div>
                        <p class="text-body">設定完成 您可以隨時進入設定修改</p>
                        <p class="text-body">使用上有任何問題 請洽管理員</p>
                      </v-col>
                    </v-row>

                    <!-- <v-row class="mt-2">
                      <v-col md="8" cols="12">
                        <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Street Name</label
                        >
                        <v-text-field
                          hide-details
                          outlined
                          color="rgba(0,0,0,.6)"
                          light
                          placeholder="Eg. Soft"
                          class="
                            font-size-input
                            placeholder-lighter
                            border border-radius-md
                            mt-2
                          "
                        >
                        </v-text-field>
                      </v-col>
                      <v-col md="4" cols="12">
                        <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Street No</label
                        >
                        <v-text-field
                          hide-details
                          outlined
                          color="rgba(0,0,0,.6)"
                          light
                          placeholder="Eg. 221"
                          class="
                            font-size-input
                            placeholder-lighter
                            border border-radius-md
                            mt-2
                          "
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2">
                      <v-col md="7" cols="12">
                        <label class="text-xs text-typo font-weight-bolder ms-1"
                          >City</label
                        >
                        <v-text-field
                          hide-details
                          outlined
                          color="rgba(0,0,0,.6)"
                          light
                          placeholder="Tokyo"
                          class="
                            font-size-input
                            placeholder-lighter
                            border border-radius-md
                            mt-2
                          "
                        >
                        </v-text-field>
                      </v-col>
                      <v-col md="5" cols="12">
                        <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Country</label
                        >
                        <v-select
                          :items="country"
                          value="Argentina"
                          color="rgba(0,0,0,.6)"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mt-2
                            mb-0
                          "
                          outlined
                          single-line
                          height="38"
                        >
                        </v-select>
                      </v-col>
                    </v-row> -->
                  </div>
                  <div class="d-flex">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs text-dark
                        btn-light
                        bg-gradient-light
                        py-5
                        px-6
                        mt-2
                        mb-2
                        ms-2
                      "
                      @click="e1 = 2"
                    >
                      回上一步
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-default
                        py-5
                        px-6
                        mt-2
                        mb-2
                        me-2
                        ms-auto
                      "
                      color="primary"
                      @click="toDashboard"
                    >
                      完成
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-col>
        </v-row>
      </v-stepper>
    </v-row>
  </v-container>
</template>
<script>
import UserChangePassword from "@/views/Pages/General/Widgets/UserChangePassword.vue";
import ThemeMode from "@/views/Pages/CRM/Widgets/ThemeMode.vue";
export default {
  name: "CRMWizard",
  data() {
    return {
      e1: 1,
      toggle_exclusive: -1,
    };
  },
  components: {
    UserChangePassword,
    ThemeMode,
  },
  methods: {
    onPasswordChanged() {
      this.e1 = 2;
    },
    toDashboard() {
      this.$router.push({ name: "Dashboard" });
    },
    updateSidebarTheme(theme) {
      this.$emit("updateSidebarTheme", theme);
    },
  },
};
</script>
